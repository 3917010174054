import React, { useEffect, useState, useRef } from 'react';
import { Helmet } from 'react-helmet';
import useWallet from '../context/wallet';
import { useNavigate, useParams, Link } from 'react-router-dom';
import { gql, useQuery } from '@apollo/client';
import p5 from 'p5';
// import { Sketch } from 'react-p5';
import '../styles/data.css';
import tokensData from '../tokens.json'; // Import the local JSON file


const Data = () => {

  const { wallet, disconnectWallet, } = useWallet();
  const navigate = useNavigate();
  const { tokenId, faContract } = useParams();
  const [desc, setDesc] = useState();
  const [coordinates, setCoordinates] = useState();
  const [svg, setSvg] = useState();
  const [initial, setInitial] = useState();
  const [reveal, setReveal] = useState();
  const [isLoading, setIsLoading] = useState(true);
  const p5ContainerRef = useRef();
  const container = document.querySelector('.script-container');

  const handleSignOut = async () => {
    await disconnectWallet();
    navigate('/');
  };
  const handleGoToPreviousPage = () => {
    // Use the navigate function to go to the previous page
    navigate(-1);
  };

  const ASSET_QUERY = gql`
      query AssetQuery {
          token(where: { fa_contract: { _eq: "${faContract}" }, token_id: {_eq: "${tokenId}"} }) {
              name
              display_uri
              artifact_uri
              thumbnail_uri
              fa {
                  name
                  contract
              }
          }
      }
  `;

  const { data: { token = [] } = {}, loading: isQueryLoading } =
    useQuery(ASSET_QUERY);

  const { name, display_uri, artifact_uri, thumbnail_uri, fa: { contract } = {} } = token[0] || {};

  useEffect(() => {
    const fetchData = async () => {
      try {
        // Find the token data based on tokenId and faContract
        const token = tokensData.tokens.find(
          (t) => t.tokenId === tokenId && t.faContract === faContract
        );
        console.log('Token Data:', token); // Add this line

        if (token) {
          // Update state with the token data from the local JSON file
          setDesc(token.desc);
          setCoordinates(token.coordinates);
          setSvg(token.svg);
          setInitial(token.initial);
          setReveal(token.reveal);
          setIsLoading(false);
        } else {
          console.error('Token not found');
        }
      } catch (error) {
        console.error('Error fetching data', error);
      }
    };

    fetchData();
  }, [tokenId, faContract]);

  const sketch = (p) => {
    // p is a reference to the p5 instance this sketch is attached to
    // Include your full p5.js logic here
    let canvasWidth, canvasHeight;
    let foregroundImg;
    let backgroundImg;
    let brushSize = 30;
    let ar = 16/9;
   

    p.preload = function() {
      
      foregroundImg = p.loadImage(`https://ipfs.io/ipfs/${display_uri.substring(7)}`);
      
      backgroundImg = p.loadImage(reveal);
    };
    
    let isDrawing = true;

    p.setup = function() {
      canvasWidth = container.clientWidth;
      canvasHeight = container.clientHeight;
      let canvas = p.createCanvas(canvasWidth, canvasHeight);
      p.background(0);

      foregroundImg.resize(p.width*ar, p.height);
      backgroundImg.resize(p.width*ar, p.height);

      // p.mousePressed(startDrawing);
      // p.mouseReleased(stopDrawing);

      canvas.ontouchstart = function(event) {
        startDrawing();
        return false;
      };

      canvas.ontouchend = function(event) {
        stopDrawing();
        return false;
      };

      // Set isDrawing to false after rendering the initial background
      isDrawing = false;
    };

    const startDrawing = () => {
      isDrawing = true;
    };

    const stopDrawing = () => {
      isDrawing = false;
    };

    p.mousePressed = function() {
      startDrawing();
    };

    p.mouseReleased = function() {
      stopDrawing();
    };

    p.draw = function() {
      // your draw code here

      p.scale(1.0); // set scale to fit the image

      let centerx = (p.width - p.width*(ar)) / 2;

      if (!isDrawing) {
        p.background(255, 0);
        p.image(foregroundImg, centerx, 0, p.width*(ar), p.height);
      } else {
        let scaledMouseX = p.mouseX; // adjust to scale
        let scaledMouseY = p.mouseY; // adjust to scale

        // Adjust the brush size to have a 1:1 aspect ratio
        let brushSizeX = brushSize;
        let brushSizeY = brushSize;

        // Calculate the position of the brush to synchronize with the mouse
        let brushPosX = scaledMouseX;
        let brushPosY = scaledMouseY;

        p.noFill();
        p.stroke(255);
        p.strokeWeight(2);
        p.rectMode(p.CENTER);

        p.rect(scaledMouseX, scaledMouseY, brushSize, brushSize);

        foregroundImg.copy(
            backgroundImg,
            brushPosX*ar,
            brushPosY,
            brushSizeX,
            brushSizeY,
            brushPosX*ar,
            brushPosY,
            brushSizeX,
            brushSizeY
        );

        p.image(foregroundImg, centerx, 0, p.width*(ar), p.height);
      }
    };

    p.mouseWheel = function(event) {
      brushSize += event.delta;
      brushSize = p.constrain(brushSize, 10, 100);
      return false;
    };
  };

  useEffect(() => {
    if (token.length) {
      // On component creation, instantiate a p5 object with the sketch and container reference
      const p5Instance = new p5(sketch, p5ContainerRef.current);

      // On component destruction, delete the p5 instance
      return () => {
        p5Instance.remove();
      };
    }
  }, [p5ContainerRef, token]);

  const isDataLoading = isLoading || isQueryLoading;

  // if (isDataLoading) return <p>Loading...</p>;
  console.log({ token, name, contract, display_uri, artifact_uri, desc, coordinates, svg, reveal, initial, tokenId, faContract });
  // if (token.length === 0) return <div> No data found </div>;
  console.log({ token });
  return (
    <>
      <Helmet>
        <title>Reveal The Lake</title>
      </Helmet>
      <header style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <div style={{ marginLeft: '10px' }}>
          {/* Add your arrow button or any other content for the left side */}
          <button className='button' onClick={handleGoToPreviousPage}>
            &larr;
          </button>
        </div>
        
        {wallet && 
          <div style={{ marginRight: '10px' }}>
            <button className='button' onClick={handleSignOut}>
              disconnect wallet
            </button>
          </div>
        }
      </header>

      <div className='page-container'>
        <div className='text-container'>
          <h2>INSTRUCTIONS</h2>
          <p> - click and drag the mouse over the image to reveal the original image of the lake* </p>
          <p> - use 'scroll up/down' with your mousewheel or touchpad to change the size of the scribing brush* </p>
          <p> - click the button below to go to commit-reveal step</p>
          <div className='button-container'>
                 <a
                  href='https://heart-shaped-commit-reveal.vercel.app/'
                  target='_blank'
                  className='button'
                  >
                  commit reveal
                 </a>
          </div>
          <p className='footnote'> * scribing tool only works on Desktop, if you are viewing this page from mobile you can also find the full size reveal image if you click on ipfs link (see metadata section)  
          </p> 
        </div>
        {/*<div ref={p5ContainerRef}></div>*/}
        <div className='script-container' ref={p5ContainerRef}>

          {/*<div ref={p5ContainerRef}></div>*/}
          {/* <img src={`https://ipfs.io/ipfs/${display_uri.substring(7)}`} alt='' /> */}
          {/* {<div className='script-container' id='p5-canvas-container' />} */}

        </div>
        <div className='text-container-right'>
          <h2>METADATA</h2>
          <p>Name: {desc}</p>
          <p>Coordinates*: <br /> {coordinates}</p>
          <p>Initial Image (full size):<br /> <a href= {initial} target="_blank" rel="noopener noreferrer" style={{ color: 'white' }}> ipfs link </a> </p> 
          <p>Reveal Image (full size):<br /> <a href= {reveal} target="_blank" rel="noopener noreferrer" style={{ color: 'white' }}> ipfs link </a> </p> 
          <p> <br /> Click to download .svg:</p>
          <img 
             src= {svg} 
             alt="SVG Image"
             className="svg-image"
             onClick={() => window.open(svg, '_blank')}/>
             <p className='footnote'> * Copy / paste coordinates into Google Maps search bar to locate the lake. </p> 
          <p>{}</p>
        </div>
      </div>

    </>

  );

};

export default Data;