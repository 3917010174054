import React from 'react';
import { Helmet } from 'react-helmet';
import '../styles/home.css';
import useWallet from '../context/wallet';
import { useNavigate } from 'react-router-dom';
import { useQuery, gql } from '@apollo/client';

const DEFAULT_ASSETS = ['1-KT1NW4pmYyjzm2QHBkaQywfTC77nro1yrLCF', '4-KT1NW4pmYyjzm2QHBkaQywfTC77nro1yrLCF', '55897-KT1EfsNuqwLAWDd3o4pvfUx1CAh5GMdTrRvr', '5-KT1NW4pmYyjzm2QHBkaQywfTC77nro1yrLCF'];

const Home = () => {
  const { wallet, connectWallet, disconnectWallet } = useWallet();
  const navigate = useNavigate();

  const handleSignOut = async () => {
    await disconnectWallet();
    navigate('/');
  };
  const handleGoToPreviousPage = () => {
    // Use the navigate function to go to the previous page
    navigate(-1);
  };

  const checkIfAssetExistsInDataBase = (slug = '', token_data) => {
    const { fa_contract, token_id } = token_data;
    console.log({ slug, token_data });
    const splittedSlug = slug.split('-');
    return (
      splittedSlug[0] === token_id &&
      splittedSlug[1] === fa_contract
    );
  };

  const ASSETS_QUERY = gql`
  query AssetsQuery {
    walletAssets: token(
      where: {
        _and: [
          { holders: { holder_address: { _eq: "${wallet}" } } },
          { fa_contract: { _eq: "KT1RcAzAx8BfeYE7dX7jFEvmHKbTcYMkCUgc" } }
        ]
      }
      order_by: { timestamp: desc }
    ) {
      token_id
      fa_contract
      display_uri
      name
      thumbnail_uri
      fa {
        name
      }
    }
    publicAssets: token(
      where: { fa_contract: { _eq: "KT1RcAzAx8BfeYE7dX7jFEvmHKbTcYMkCUgc" } }
      order_by: { token_id: asc }
    ) {
      token_id
      fa_contract
      display_uri
      name
      thumbnail_uri
      fa {
        name
      }
    }
  }   
`;


  const { data: { walletAssets = [], publicAssets = [] } = {}, loading, error } = useQuery(ASSETS_QUERY);

  if (wallet && (loading || walletAssets.length === 0)) return <p>Loading...</p>;
  
  const assets = walletAssets.map((token_data) => (
    <a href={`data/${token_data.token_id}/${token_data.fa_contract}`}>
      <img
        className='wallet-item'
        key={token_data.token_id}
        src={`https://ipfs.io/ipfs/${token_data.display_uri.substring(7)}`}
        alt=''
      />
    </a>
  ));
  
  return (
    <>
      <Helmet>
        <title>Heartshaped</title>
      </Helmet>
      <header style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        {wallet && 
         <div style={{ marginLeft: '30px', color: 'white' }}>
          Choose the Token:
         </div>
        }
  
        {wallet && 
         <div style={{ marginRight: '30px' }}>
          <button className='button' onClick={handleSignOut}>
           disconnect wallet
          </button>
         </div>
        }
      </header>
      <div className='container'>
        
        {!wallet ? (
          <>
            <div className='info-container'>
              <h1>
              <a href="/logo.png" target="_blank">
              <img src="/logo.png" alt="collection logo" className="logo" />
              </a>
               HEART-SHAPED</h1>  
              <p>HEART-SHAPED is a discovery experience based on the collection of images, each representing the real mountain lake somewhere in Switzerland. Not just a random lake, but resembling a shape of ❤. <br />
                 Behind the layers of procedural creative coding, a unique body of Nature is hidden, and the collectors get to decide whether to reveal it to others.</p>             
              
              <div className='button-container'>
              <p className='buttonDesc' >View collection:</p>
                <a
                  href='https://objkt.com/collections/KT1RcAzAx8BfeYE7dX7jFEvmHKbTcYMkCUgc'
                  target='_blank'
                  className='button'
                >
                  objkt.com
                </a>
                <p className='buttonDesc'>Connect* to reveal the lake:</p>
                <button onClick={connectWallet} className='button'>
                  connect wallet
                </button>
                <p className='footnote'>* The reveal feature is only available for the holders of the HEART-SHAPED pieces for now. Once all the tokens are revealed, the app will be open to the public. <br /> <br />
                   * Wallet connection is powered by the Taquito protocol and scans your wallet for the assets in scope the HEART-SHAPED collection only. If you have the one or more tokens from the collection in your wallet, it will appear on the next step after you’ve connected your wallet.  <br /> <br />
                   * If you have any issues or questions about the collection or this app, please contact hello@uncorporated.studio or x/@imp3dim3nta. Have a fun discovery!</p>
              </div>
            </div>
            <div className='grid-container'>
            {publicAssets.map((token_data) => (
           <div className='grid-item' key={token_data.token_id}>
          <img 
              src={`https://ipfs.io/ipfs/${token_data.display_uri.substring(7)}`}
              alt={`Image ${token_data.token_id}`}
           />
           </div>
           ))}
            </div>
          </>
        ) : <div>{assets.length ? assets : <p>no assets</p>}
        </div>}
      </div>
    </>
  );
};

export default Home;
